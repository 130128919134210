<template>
  <div id="formulario-edit-info">
    <ExibirErrosConcept
      :errorsValidacao="errorsValidacao"
      ref="errosComponent"
    ></ExibirErrosConcept>
    <vx-card>
      <div class="vx-row mb-4">
        <div class="vx-col md:w-1/2 w-full">
          <vs-button
            class="ml-auto mt-2"
            size="small"
            @click="gravar"
            color="success"
            >Gravar</vs-button
          >
          <vs-button
            class="ml-4 mt-2"
            size="small"
            @click="excluir"
            v-show="isDeletable"
            color="danger"
            >Excluir</vs-button
          >
          <vs-button
            class="ml-4 mt-2"
            size="small"
            type="border"
            color="dark"
            @click="voltar"
            >Voltar</vs-button
          >
        </div>
      </div>
      <div class="vx-row" v-if="data_local.hasOwnProperty('id')">
        <div class="vx-col w-1/2">
          <label class="vs-input--label">Cliente:</label>
          <h6>{{ data_local.cliente.nome }}</h6>
        </div>
        <div class="vx-col w-1/2">
          <label class="vs-input--label">Veículo:</label>
          <h6>{{ data_local.veiculo.placa }}</h6>
        </div>
      </div>
      <div class="vx-row" v-if="!data_local.hasOwnProperty('id')">
        <div class="vx-col w-full">
          <label class="vs-input--label">Cliente:</label>
          <v-select
            label="nome"
            :options="listaClientes"
            placeholder="Selecione o cliente"
            :filter="pesquisarCliente"
            v-model="data_local.cliente"
            @input="atualizarListaVeiculos"
            class="w-full vs-input--label"
          >
            <template v-slot:option="option">
              {{ option.nome }}
            </template>
          </v-select>
        </div>
      </div>
      <div class="vx-row mt-2" v-if="!data_local.hasOwnProperty('id')">
        <div class="vx-col w-full">
          <label class="vs-input--label">Veículo:</label>
          <v-select
            label="placa"
            :options="listaVeiculos"
            placeholder="Selecione o veículo"
            :reduce="(v) => v.idVeiculo"
            v-model="data_local.veiculo.id"
            class="w-full vs-input--label"
          >
            <template v-slot:option="option">
              {{ option.placa }}
            </template>
          </v-select>
        </div>
      </div>
      <div class="vx-row mt-2">
        <div class="vx-col w-1/2">
          <label class="vs-input--label">Data/Hora do Contato</label>
          <flat-pickr
            v-model="data_local.dataHoraContatoComCliente"
            :config="configDateTimePicker"
            placeholder="Data"
          />
        </div>
        <div class="vx-col w-1/2">
          <label class="vs-input--label">Em manutenção ?</label>
          <v-select
            :options="listaOpcoesSIM_NAO"
            :reduce="(tipo) => tipo.data"
            v-model="data_local.emManutencao"
            class="v-select"
          >
          </v-select>
        </div>
      </div>
      <div class="vx-row mt-2">
        <div class="vx-col w-1/2">
          <label class="vs-input--label">Nome do Contato</label>
          <vs-input
            class="w-full"
            v-model="data_local.nomeContato"
            maxlength="100"
          />
        </div>
        <div class="vx-col w-1/2">
          <label class="vs-input--label">Telefone do Contato</label>
          <vs-input
            class="w-full"
            v-model="data_local.telefoneContato"
            v-mask="['(##) #####-####', '(##) ####-####']"
          />
        </div>
      </div>
      <div class="vx-row mt-2">
        <div class="vx-col w-full">
          <label class="vs-input--label">Descrição</label>
          <vs-textarea v-model="data_local.descricao" counter="1000" />
        </div>
      </div>
      <div class="vx-row mt-2">
        <div class="vx-col w-full">
          <label class="vs-input--label">Tratativa</label>
          <vs-textarea v-model="data_local.tratativa" counter="1000" />
        </div>
      </div>
      <div class="vx-row mt-2">
        <div class="vx-col w-full">
          <label class="vs-input--label">Resposta Cliente</label>
          <vs-textarea v-model="data_local.respostaCliente" counter="1000" />
        </div>
      </div>
      <div class="vx-row mt-2">
        <div class="vx-col w-1/2">
          <label class="vs-input--label">Retirar do relatório até:</label>
          <flat-pickr
            v-model="data_local.desconsiderarAte"
            :config="configDatePicker"
            placeholder="Data"
          />
        </div>
        <div class="vx-col w-1/2" v-if="data_local.hasOwnProperty('id')">
          <label class="vs-input--label">Cadastrado em:</label>
          <p class="mt-2">{{ data_local.dataHoraCadastroFormatada }}</p>
        </div>
      </div>
    </vx-card>
  </div>
</template>

<script>
import axios from "@/axios.js";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { Portuguese as PortugueseLocale } from "flatpickr/dist/l10n/pt.js";
import { CONSTANTE_OPCAO_SIM_NAO } from "@/constantesComboBox.js";

const URL_SERVLET = "/rest/VeiculoHistorico";
const NAME_ROUTE_SEARCH = "cadastro-ocorrencia-veiculo-pesquisar";

export default {
  components: {
    flatPickr,
  },
  props: {
    registroOriginal: {
      type: Object,
      required: false,
      default: function () {
        return {
          cliente: null,
          veiculo: { id: null, placa: null },
          dataHoraContatoComCliente: null,
          nomeContato: null,
          telefoneContato: null,
          emManutencao: null,
          descricao: null,
          tratativa: null,
          respostaCliente: null,
          desconsiderarAte: null,
        };
      },
    },
  },
  data() {
    return {
      errorsValidacao: [],
      data_local: JSON.parse(JSON.stringify(this.registroOriginal)),
      configDatePicker: {
        altInput: true,
        altFormat: "d-m-Y",
        dateFormat: "Y-m-d",
        altInputClass: "vs-inputx vs-input--input normal hasValue text-center",
        locale: PortugueseLocale,
      },
      configDateTimePicker: {
        altInput: true,
        altFormat: "d-m-Y H:i",
        dateFormat: "Y-m-d H:i:S",
        altInputClass: "vs-inputx vs-input--input normal hasValue text-center",
        locale: PortugueseLocale,
        enableTime: true,
        minuteIncrement: 1,
      },
      listaClientes: [],
      listaVeiculos: [],
    };
  },
  mounted() {
    this.fetchListaClientes();    
  },
  computed: {
    isDeletable() {
      return this.data_local != null && this.data_local.hasOwnProperty("id");
    },
  },
  created() {
    this.listaOpcoesSIM_NAO = CONSTANTE_OPCAO_SIM_NAO;
  },
  methods: {
    atualizarListaVeiculos() {
        this.data_local.veiculo= { id: null, placa: null };
        this.fetchListaVeiculos();    
    },
    fetchListaVeiculos() {            
      if (!this.data_local.cliente) {
        this.listaVeiculos = [];
      }
      const params = new URLSearchParams();
      params.append("outputFormat", "JSON");
      params.append("cpfcnpj", this.data_local.cliente.CNPJCPF);
      params.append("idUsuarioMonitorPanico",this.$store.state.AppActiveUser.id);

      axios.post("/RelatorioCliente", params, {
          credentials: "include",
          responseEncoding: "iso88591",
          withCredentials: true,
        })
        .then((response) => {
          this.$vs.loading.close();
          if (
            response.data.hasOwnProperty("codigo") &&
            response.data.codigo === "99"
          ) {
            this.$router.push({ path: "/" });
          } else {
            this.listaVeiculos = response.data.listaVeiculos;
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          /* eslint-disable no-console */ console.log(
            "EFETUAR LOGOFF! - fetchListaVeiculos - " + error
          );
        });
    },
    pesquisarCliente(options, varTermoPesquisa) {
      varTermoPesquisa = varTermoPesquisa.toUpperCase();
      return options.filter(function (c) {
        return (
          c.nome.toUpperCase().indexOf(varTermoPesquisa) != -1 ||
          c.razaoSocial.toUpperCase().indexOf(varTermoPesquisa) != -1 ||
          c.CNPJCPF.toUpperCase().indexOf(varTermoPesquisa) != -1
        );
      });
    },
    fetchListaClientes() {
      const params = new URLSearchParams();
      params.append("method", "FindAll");
      params.append("orderDirection", "ASC");
      params.append("outputFormat", "JSON");

      this.$vs.loading();
      axios
        .post("/ClientesRest", params, {
          credentials: "include",
          responseEncoding: "iso88591",
          withCredentials: true,
        })
        .then((response) => {
          this.$vs.loading.close();
          if (
            response.data.hasOwnProperty("codigo") &&
            response.data.codigo === "99"
          ) {
            this.$router.push({ path: "/" });
          } else {
            this.listaClientes = response.data;
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          /* eslint-disable no-console */ console.log(
            "EFETUAR LOGOFF! - fetchLista - " + error
          );
        });
    },
    excluir() {
      this.$vs.dialog({
        type: "confirm",
        color: "warning",
        title: "Atenção",
        text: "Deseja excluir ?",
        acceptText: "Sim",
        cancelText: "Não",
        accept: this.excluirRequest,
      });
    },
    excluirRequest() {
      this.$vs.loading();
      const params = new URLSearchParams();
      params.append("id", this.data_local.id);
      params.append("veiculo.id", this.data_local.veiculo.id);

      axios.post(URL_SERVLET + "/Remover", params, {
          credentials: "include",
          responseEncoding: "iso88591",
          withCredentials: true,
        })
        .then((response) => {
          if (
            response.data.hasOwnProperty("codigo") &&
            response.data.codigo === "99"
          ) {
            this.$router.push({ path: "/login" });
          } else {
            if (response.data.Resultado.hasOwnProperty("error")) {
              this.$vs.dialog({ color: "danger", title: "Erro", text: response.data.Resultado.error, acceptText: "OK", });
            } else {
              this.$router.push({ name: NAME_ROUTE_SEARCH });
            }
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          /* eslint-disable no-console */ console.log(
            "EFETUAR LOGOFF! - gravar - " + error
          );
        });
    },
    gravar() {
      if (this.formularioValido()) {
        this.$vs.loading();
        const params = new URLSearchParams();

        if (this.data_local.hasOwnProperty("id")) {
          params.append("id", this.data_local.id);
        }

        params.append("veiculo.id", this.data_local.veiculo.id);
        params.append("cliente.id", this.data_local.cliente.id);

        params.append("dataHoraContatoComCliente", this.data_local.dataHoraContatoComCliente
        );
        params.append("nomeContato", this.data_local.nomeContato);
        params.append("telefoneContato", this.data_local.telefoneContato);
        if (this.data_local.emManutencao) {
          params.append("emManutencao", this.data_local.emManutencao);
        }
        params.append("descricao", this.data_local.descricao);
        params.append("tratativa", this.data_local.tratativa);
        params.append("respostaCliente", this.data_local.respostaCliente);
        if (this.data_local.desconsiderarAte) {
          params.append("desconsiderarAte", this.data_local.desconsiderarAte);
        }

        axios.post(URL_SERVLET + "/Gravar", params, {
            credentials: "include",
            responseEncoding: "iso88591",
            withCredentials: true,
          })
          .then((response) => {
            this.$vs.loading.close();
            if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99") {
              this.$router.push({ path: "/login" });
            } else {
              if (response.data.Resultado.hasOwnProperty("error")) {
                this.$vs.dialog({
                  color: "danger",
                  title: "Erro",
                  text: response.data.Resultado.error,
                  acceptText: "OK",
                });
              } else {
                this.$router.push({ name: NAME_ROUTE_SEARCH });
              }
            }
          })
          .catch((error) => {
            this.$vs.loading.close();
            /* eslint-disable no-console */ console.log(
              "EFETUAR LOGOFF! - gravar - " + error
            );
          });
      }
    },
    voltar() {
      this.$router.push({
        name: NAME_ROUTE_SEARCH,
      });
    },
    formularioValido() {
      this.$refs.errosComponent.iniciar();
      this.errorsValidacao = [];

      if (!this.data_local.cliente || !this.data_local.cliente.id) {
        this.errorsValidacao.push("Cliente obrigatório.");
      }
      if (!this.data_local.veiculo || !this.data_local.veiculo.id) {
        this.errorsValidacao.push("Veículo obrigatório.");
      }
      if (!this.data_local.dataHoraContatoComCliente) {
        this.errorsValidacao.push("Data e Hora de Contato com o cliente obrigatório.");
      }
      if (!this.data_local.nomeContato) {
        this.errorsValidacao.push("Nome do Contato obrigatório");
      }
      if (!this.data_local.telefoneContato) {
        this.errorsValidacao.push("Telefone do Contato obrigatório");
      }
      if (!this.data_local.descricao) {
        this.errorsValidacao.push("Descrição obrigatória");
      }
      if (!this.data_local.tratativa) {
        this.errorsValidacao.push("Tratativa obrigatória");
      }
      if (!this.data_local.respostaCliente) {
        this.errorsValidacao.push("Resposta do cliente obrigatória");
      }

      if (!this.errorsValidacao.length) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style scoped>
.tabelaComScroll {
  overflow: auto;
  height: calc(100vh - 300px);
  position: relative;
}
</style>